import React from 'react';
import classNames from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import classes from './ErrorFallback.module.scss';
import { useTranslation } from 'react-i18next';
import { useRandomId } from '@/core/hooks/useRandomId.hook';
import { Icon } from '@/core/Icon.atom';
import { TextButton } from '@/core/TextButton.atom';
import { isProtractor } from '@/core/utilities';

interface ErrorFallbackProps {
  error: Error;
  /** Attempt to re-render the original content */
  retry: () => void;
}

export const ErrorFallback: React.FunctionComponent<ErrorFallbackProps> = ({ error, retry }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <img src="/img/Seeq_logo_darkBlue_sm.png" />
      </div>
      <p className={classNames(classes.title, 'h4')}>{t('ERROR_FALLBACK.TITLE')}</p>
      <p>{t('ERROR_FALLBACK.BODY')}</p>
      <p>
        <code>{error.toString()}</code>
      </p>
      <p>{t('ERROR_FALLBACK.RENDER_OR_REFRESH')}</p>
      <TextButton
        size="sm"
        variant="theme"
        extraClassNames={classes.retryButton}
        onClick={retry}
        label="ERROR_FALLBACK.RETRY"
      />
    </div>
  );
};

export const ErrorFallbackMinimal: React.FunctionComponent<ErrorFallbackProps> = ({ error, retry }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(classes.container, 'max-width-400')}>
      <h4>
        <Icon icon="fa-exclamation-triangle" type="danger" testId="show-error-fallback-popover" extraClassNames="mr5" />
        {t('ERROR_FALLBACK.TITLE')}
      </h4>
      <p>{t('ERROR_FALLBACK.BODY')}</p>
      <p>
        <code>{error.toString()}</code>
      </p>
      <TextButton
        size="sm"
        variant="theme"
        extraClassNames={classes.retryButton}
        onClick={retry}
        label="ERROR_FALLBACK.RETRY"
      />
    </div>
  );
};

export const ErrorFallbackIcon: React.FunctionComponent<ErrorFallbackProps> = ({ error, retry }) => {
  const popoverId = useRandomId();

  const popover = (
    <Popover id={popoverId} bsPrefix="greyPopover">
      <ErrorFallback error={error} retry={retry} />
    </Popover>
  );

  return (
    <>
      <OverlayTrigger trigger="click" overlay={popover} transition={false}>
        <Icon
          icon="fa-exclamation-triangle"
          large={true}
          type="danger"
          testId="show-error-fallback-popover"
          extraClassNames="cursorPointer"
        />
      </OverlayTrigger>
      {isProtractor() ? <>{error.stack}</> : ''}
    </>
  );
};
