import React from 'react';
import classNames from 'classnames';
import { IconType } from '@/core/Icon.atom';

export interface IconWithSpinnerProps {
  id?: string;
  icon?: string;
  spinning: boolean;
  large?: boolean;
  white?: boolean;
  type?: IconType;
  extraClassNames?: string;
  testId?: string;
  customId?: string;
  correctSpin?: boolean;
  disabled?: boolean;
}

/** Icon that switches to spinner when input parameter is true */
export const IconWithSpinner: React.FunctionComponent<IconWithSpinnerProps> = ({
  id,
  spinning,
  icon,
  large,
  white,
  extraClassNames,
  testId,
  customId,
  correctSpin = true,
  disabled = false,
  type = 'theme',
}) => {
  const fontCustom = icon && icon.includes('fc-');
  const colorClass = white ? 'text-white' : `sq-icon-${type}`;
  const spinningIconClasses = classNames('fa', 'fa-spinner', 'fa-spin', { 'fa-lg': large }, { correctSpin });
  const iconClasses = classNames(fontCustom ? 'fc' : 'fa', icon, {
    'fa-lg': large,
  });

  return (
    <i
      id={id}
      className={classNames(extraClassNames, colorClass, spinning ? spinningIconClasses : iconClasses, {
        'disabledLook cursorNotAllowed': disabled,
      })}
      data-testid={testId}
      data-customid={customId}
    />
  );
};
