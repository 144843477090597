// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { ButtonVariant, TextButton } from '@/core/TextButton.atom';

interface CancelAndSaveProps {
  submitFn?: (values) => any;
  cancelFn?: () => void;
  values: any[];
  btnDisabled?: boolean;
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
  cancelClassNames?: string;
  submitClassNames?: string;
  cancelBtnTestId?: string;
  submitBtnTestId?: string;
  submitBtnVariant?: ButtonVariant;
  hideSubmit?: boolean;
  hideCancel?: boolean;
  // this is useful for those rare cases where the submit button should remain in a loading state after the submitFn is
  // resolved (ex. oauth)
  continueProcessing?: boolean;
}

/**
 * Cancel and Save buttons
 */
export const CancelAndSave: React.FunctionComponent<CancelAndSaveProps> = (props) => {
  const {
    submitFn,
    cancelFn,
    btnDisabled,
    values,
    cancelBtnLabel = 'CANCEL',
    submitBtnLabel,
    cancelClassNames = '',
    submitClassNames = '',
    cancelBtnTestId = 'cancelButton',
    submitBtnTestId = 'executeButton',
    submitBtnVariant = 'theme',
    hideSubmit,
    hideCancel,
    continueProcessing,
  } = props;

  const [processing, setProcessing] = useState(false);
  const isClosed = useRef(false);

  useEffect(
    () => () => {
      isClosed.current = true;
    },
    [],
  );

  const manageSubmission = () => {
    setProcessing(true);
    return Promise.resolve()
      .then(() => submitFn(values))
      .finally(() => !isClosed.current && setProcessing(false));
  };

  return (
    <>
      {!hideCancel && (
        <TextButton
          onClick={cancelFn}
          label={cancelBtnLabel}
          size="sm"
          testId={cancelBtnTestId}
          extraClassNames={cancelClassNames || 'mr20 min-width-100 width-100 max-width-100'}
        />
      )}

      {!hideSubmit && (
        <TextButton
          type="submit"
          onClick={manageSubmission}
          label={submitBtnLabel ? submitBtnLabel : 'SAVE'}
          disabled={btnDisabled || processing || continueProcessing}
          size="sm"
          icon={processing || continueProcessing ? 'fa fa-spinner fa-spin white' : ''}
          testId={submitBtnTestId}
          extraClassNames={submitClassNames || 'min-width-100'}
          variant={submitBtnVariant}
        />
      )}
    </>
  );
};
